import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="bg-white ">
      <div class="container px-6 py-8 mx-auto">
        <div class="grid grid-cols-2 gap-6 my-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
 

          <div>
            <h3 class="text-sm font-semibold text-[#141414] ">
              BillTopNg Benefits
            </h3>

            <div class="flex flex-col items-start mt-4 space-y-4">
              <div class="text-sm text-[#141414]/80 transition-colors duration-200">
                Fast Transactions
              </div>
              <div class="text-sm text-[#141414]/80 transition-colors duration-200">
                Secure Platform
              </div>
              <div class="text-sm text-[#141414]/80 transition-colors duration-200">
                Hassle-Free Experience
              </div>
              <div class="text-sm text-[#141414]/80 transition-colors duration-200">
                24/7 Customer Support
              </div>
            </div>
          </div>

          <div>
            <h3 class="text-sm font-semibold text-[#141414] ">Support</h3>

            <div class="flex flex-col items-start mt-4 space-y-4">
              <Link
                to=""
                class="text-sm text-[#141414]/80 transition-colors duration-200"
              >
                Give us Feedback
              </Link>
              <Link
                to=""
                class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
              >
                Help Center
              </Link>
              <div class="text-sm text-[#141414]/80 transition-colors duration-200">
                Live Chat
              </div>
            </div>
          </div>

          <div>
            <h3 class="text-sm font-semibold text-[#141414] ">Resources</h3>

            <div class="flex flex-col items-start mt-4 space-y-4">
              <Link
                to=""
                class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
              >
                FAQ
              </Link>
              <Link
                to=""
                class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
              >
                Blog
              </Link>
            </div>
          </div>

          <div>
            <h3 class="text-sm font-semibold text-[#141414] ">Contact</h3>

            <div class="flex flex-col items-start mt-4 space-y-4">
              <p class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600 cursor-pointer">
                support@billtopng.com
              </p>
            </div>
          </div>
        </div>

        <hr class="my-6 border-gray-200 md:my-8 dark:border-gray-700" />

        <div class="flex flex-col items-center justify-between sm:flex-row">
          <div className="flex gap-x-5">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_335_1565)">
                <mask
                  id="mask0_335_1565"
                  style={{ "mask-type": "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="25"
                  height="25"
                >
                  <path
                    d="M23.2143 0H1.78571C0.799491 0 0 1.11929 0 2.5V22.5C0 23.8807 0.799491 25 1.78571 25H23.2143C24.2005 25 25 23.8807 25 22.5V2.5C25 1.11929 24.2005 0 23.2143 0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_335_1565)">
                  <path
                    d="M22.991 0.3125H2.00886C1.02264 0.3125 0.223145 1.43179 0.223145 2.8125V22.1875C0.223145 23.5682 1.02264 24.6875 2.00886 24.6875H22.991C23.9772 24.6875 24.7767 23.5682 24.7767 22.1875V2.8125C24.7767 1.43179 23.9772 0.3125 22.991 0.3125Z"
                    stroke="white"
                    stroke-width="0.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.667 0H25V25H16.667V0ZM0 0H8.33304V25H0V0Z"
                    fill="#189B62"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_335_1565">
                  <rect width="25" height="25" rx="12.5" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Nigeria
          </div>

          <div className="flex gap-x-4 lg:gap-x-10">
            <a
              href="!#"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/BillTopNg"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Instagram
            </a>
            <a
              href="!#"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Facebook
            </a>
          </div>
        </div>

        <hr class="my-6 border-gray-200 md:my-8 " />

        <div class="flex flex-col items-center justify-between sm:flex-row">
          <p class="my-2 text-sm text-gray-500 sm:mt-0 ">
            © Copyright 2024. All Rights Reserved.
          </p>

          <div className="flex gap-x-3 lg:gap-x-10">
            <Link
              to="/privacy"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Privacy Policy
            </Link>
            <a
              href="#"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Terms of Use
            </a>
            <a
              href="#"
              class="text-sm text-[#141414]/80 transition-colors duration-200 hover:text-blue-600"
            >
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
