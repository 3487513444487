import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Privacy from "./pages/Privacy";
import Delete from "./pages/Delete";
function App() {
  return (
    <div className="font-SF Pro Display">
      <TawkMessengerReact
        propertyId="653384a2f2439e1631e6acd6"
        widgetId="1hd8js7fu"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="delete" element={<Delete />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
