import React from "react";

function Marquee() {
  return (
    <div className="bg-[#292929] text-[#fff] text-sm font-bold  ">
      <div className="px-6 lg:px-24  mx-auto">
        <div className="flex py-4 items-center gap-5 lg:gap-10 -mx-3 overflow-y-auto whitespace-nowrap scrollbar-hide inset-x-0 ">
          AIRTIME{" "}
          <span className="text-[#0061EF] font-black text-3xl -mb-2">*</span>{" "}
          DATA
          <span className="text-[#0061EF] font-black text-3xl -mb-2 ">
            *
          </span>{" "}
          <span className="flex">CABLE TV </span>{" "}
          <span className="text-[#0061EF] font-black text-3xl -mb-2">*</span>
          <span className="flex">VIRTUAL CARDS </span>
          <span className="text-[#0061EF] font-black text-3xl -mb-2">*</span>
          BETTING{" "}
          <span className="text-[#0061EF] font-black text-3xl -mb-2">*</span>
          TRANSFER{" "}
          <span className="text-[#0061EF] font-black text-3xl -mb-2">*</span>
          UTILITY
          <span className="text-[#0061EF] font-black text-3xl -mb-2">
            *
          </span>{" "}
          GIFTCARDS
        </div>
      </div>
    </div>
  );
}
export default Marquee;
