import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

function Header() {
	const [isOpen, setIsOpen] = useState(false);
	return (
    <div className="z-[100] sticky -top-10">
      <div className="text-center bg-[#0061EF] text-[#fff] text-xs p-3">
        🎉BillTopNg has come to serve you better.
      </div>
      <header className="bg-white  shadow-md  ">
        <nav
          className="mx-auto hidden lg:flex max-w-7xl items-center justify-between   px-10   "
          aria-label="Global"
        >
          <Link to="/" className=" m-5">
            <img className="h-11 w-auto" src="../logo.png" alt="" />
          </Link>

          <div className="flex gap-10 items-center">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#0A0E27] transition-colors duration-300 border-b-2 border-[#0061EF] p-1 hover:text-blue-500 "
                  : "text-sm text-[#0A0E27] transition-colors duration-300  hover:text-blue-500 "
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#0A0E27] transition-colors duration-300 border-b-2 border-[#0061EF] p-1 hover:text-blue-500 "
                  : "text-sm text-[#0A0E27] transition-colors duration-300  hover:text-blue-500 "
              }
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#0A0E27] transition-colors duration-300 border-b-2 border-[#0061EF] p-1 hover:text-blue-500 "
                  : "text-sm text-[#0A0E27] transition-colors duration-300  hover:text-blue-500 "
              }
            >
              Contact Us
            </NavLink>
          </div>
        </nav>

        <div className="lg:hidden item-center  flex justify-between px-3 py-5 bg-[#fff] ">
          <Link to="/">
            <span className="sr-only">Atoovis</span>
            <img className="h-10 w-auto" src="../logo.png" alt="" />
          </Link>

          <div class="flex lg:hidden">
            {isOpen ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
                class="text-[#062F70]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#062F70"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                }}
                class="text-[#062F70]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="6" rx="3" fill="#062F70" />
                  <rect y="10" width="30" height="6" rx="3" fill="#062F70" />
                  <rect y="20" width="15" height="6" rx="3" fill="#062F70" />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div
          class={`${
            isOpen
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-[#fff] space-y-3`}
        >
          <div className="flex flex-col px-2 -mx-4 space-y-2">
            <Link
              to="/#"
              className="px-2.5 py-2 text-[#062F70] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Home
            </Link>

            <Link
              to="/about"
              className="px-2.5 py-2 text-[#062F70] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className="px-2.5 py-2 text-[#062F70] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
